<template>
  <div class="box" style="color: #000">
    <h2 style="text-align: center; font-size: 17px;padding:15px 0">{{COMPANY_NAME_All}}直播用户服务协议</h2>
    &emsp;&emsp;尊敬的用户您好！请在注册{{COMPANY_NAME_All}}直播网站账号之前仔细阅读此协议（“本协议”）。本协议是您与{{COMPANY_NAME_All}}（“{{COMPANY_NAME_All}}直播”）之间关于您注册并使用{{COMPANY_NAME_All}}直播网站账号及相关服务所订立的协议。本协议已对与您的权益具有或可能具有重大关系的条款用粗体字予以标注，请您注意阅读。<br />
    1、重要须知<br />
    &emsp;&emsp;1.1<br />
    &emsp;&emsp;您注册及/或使用平台账号即表示您认可并同意遵守以下条款，以及我们后续对条款随时所作的修改。若您对本协议存在疑问，或者无法接受全部或者部分条款，可通过本协议附则所列方式联系我们，在您未与我们沟通一致前，请停止注册及使用平台账号。<br />
    &emsp;&emsp;1.2<br />
    &emsp;&emsp;请确认您已年满18周岁，或您已年满16周岁且依靠自己的劳动收入作为主要生活来源，否则请您在法定监护人陪同下仔细阅读本协议并决定是否接受本协议，或者停止注册及使用本协议项下的账号及服务。<br />
    &emsp;&emsp;1.3<br />
    &emsp;&emsp;用户欲注册和使用{{COMPANY_NAME_All}}直播网站，必须事先认真阅读、充分理解本协议中各条款，尤其应特别注意免除或者限制{{COMPANY_NAME_All}}直播责任的免责条款及对用户的权利限制条款。如用户不同意本协议，用户应不注册使用或主动取消{{COMPANY_NAME_All}}直播提供的服务。若用户使用第三方账号登录，也请用户务必认真阅读本协议，用户的登录、查看、发布、使用行为将被视为对本协议的完全接受。<br />
    &emsp;&emsp;1.4<br />
    &emsp;&emsp;{{COMPANY_NAME_All}}直播网站是向用户提供一种更方便地进行音视频直播、观看音视频直播并与用户进行互动的音视频社交服务平台，旨在为您提供虚拟礼物、技术支持、流量入口等进行音视频社交所需的非独家的软件许可使用及信息网络服务。<br />
    &emsp;&emsp;1.5<br />
    &emsp;&emsp;在用户接受本协议之后，此文本可能因国家政策、产品以及履行本协议的环境发生变化而进行修改，修改后的协议发布在{{COMPANY_NAME_All}}直播网站上。若用户对修改后的协议有异议的，请立即停止访问、使用{{COMPANY_NAME_All}}直播网站，若用户继续访问或使用，视为对修改后的协议予以认可。<br />
    &emsp;&emsp;1.6<br />&emsp;&emsp;{{COMPANY_NAME_All}}直播网站会在本协议的基础上，制定发布平台规则，平台规则是本协议的有效组成部分，用户对本协议的接受与遵守也视为对平台规则的接受与遵守。<br />
    2、{{COMPANY_NAME_All}}直播网站账号的注册、使用与保护 <br />
    &emsp;&emsp;2.1<br />
    &emsp;&emsp;用户需通过实名手机号注册（升级）{{COMPANY_NAME_All}}直播网站账号，注册（升级）成功之后，{{COMPANY_NAME_All}}直播会按系统规则自动分配给用户一个号码，该号码即为{{COMPANY_NAME_All}}直播网站账号。用户需自行设置符合安全要求的密码。<br />
    &emsp;&emsp;2.2<br />
    &emsp;&emsp;{{COMPANY_NAME_All}}直播网站账号的性质是用户使用{{COMPANY_NAME_All}}直播服务的凭证，{{COMPANY_NAME_All}}直播网站账号的所有权属于{{COMPANY_NAME_All}}直播，用户仅有账号的使用权。<br />
    &emsp;&emsp;用户不得有偿或无偿转让{{COMPANY_NAME_All}}直播网站账号，以免产生纠纷。用户应当自行承担由此产生的任何责任，同时{{COMPANY_NAME_All}}直播保留追究上述行为人法律责任的权利。<br />
    &emsp;&emsp;2.3<br />
    &emsp;&emsp;{{COMPANY_NAME_All}}直播网站账号还是用户持有、使用相关虚拟财产、道具的身份凭证。用户若需要接受{{COMPANY_NAME_All}}直播提供的增值服务，{{COMPANY_NAME_All}}直播网站账号同时也是用户支付费用、接受增值服务的凭证。<br />
    &emsp;&emsp;2.4<br />
    &emsp;&emsp;{{COMPANY_NAME_All}}直播网站账号使用权仅属于申请注册并完成实名认证的用户，禁止账号的赠与、借用、租用、转让或售卖。具体的实名认证方式，可能包括基于移动电话号码、基于身份证明文件等一种或多种方式的结合，具体将按照您使用的特定服务内容、国家法律法规的特定要求有所差异。<br />
    &emsp;&emsp;2.5<br />
    &emsp;&emsp;用户保证注册{{COMPANY_NAME_All}}直播网站账号时填写的身份信息是真实的，任何非法、不真实、不准确的用户信息所产生的责任由用户承担。用户应不断更新注册资料，符合及时、详尽、真实、准确的要求。所有原始键入的资料将引用用户的账号注册资料。如果因用户的注册信息不真实而引起的问题，以及对问题发生所带来的后果，{{COMPANY_NAME_All}}直播不负任何责任。<br />
    &emsp;&emsp;2.6<br />
    &emsp;&emsp;用户在{{COMPANY_NAME_All}}直播网站账号的昵称须遵守《互联网用户账号名称管理规定》及国家法律法规、监管要求、平台规则关于昵称合法性的要求，不得设置任何包含危害国家安全、国家统一、社会稳定的内容，或侮辱诽谤、色情、暴力、引起他人不安及任何违反国家法律法规政策的内容作为昵称；否则{{COMPANY_NAME_All}}直播可回收您的账号昵称，并重置为默认状态，情节严重的，{{COMPANY_NAME_All}}直播可能会冻结修改昵称权限甚至封禁账号。<br />
    &emsp;&emsp;用户理解：{{COMPANY_NAME_All}}直播将随着国家监管的要求和变化，而不时调整对账号昵称合法性的判断标准；账号昵称的回收、重置不影响用户以邮箱、手机号码、第三方认证方式登录{{COMPANY_NAME_All}}直播网站并使用{{COMPANY_NAME_All}}直播提供的平台服务。<br />
    &emsp;&emsp;2.7<br />
    &emsp;&emsp;为了充分利用{{COMPANY_NAME_All}}直播网站账号资源，如果用户存在长期未登陆使用{{COMPANY_NAME_All}}直播网站账号的情形，{{COMPANY_NAME_All}}直播有权对账号进行注销、回收、替换或采取删除该账户在{{COMPANY_NAME_All}}直播网站数据库中的任何记录（包括但不限于注册信息、虚拟礼物信息等）等清理措施，相应服务同时终止。{{COMPANY_NAME_All}}直播在对此类账户进行清理前，将以包括但不限于弹窗、网站公告、站内消息、客户端推送信息等方式通知用户。<br />
    &emsp;&emsp;2.8<br />
    &emsp;&emsp;用户的{{COMPANY_NAME_All}}直播网站账号、密码属于保密信息，{{COMPANY_NAME_All}}直播应当采取积极的措施保护用户账号、密码的安全。用户的注册信息作为{{COMPANY_NAME_All}}直播的商业秘密进来保护。但用户同时明白，互联网的开放性以及技术更新非常快，非{{COMPANY_NAME_All}}直播可合理控制的因素导致用户泄漏的，{{COMPANY_NAME_All}}直播不承担责任。<br />
    &emsp;&emsp;2.9<br />
    &emsp;&emsp;用户应当妥善保管自己的{{COMPANY_NAME_All}}直播网站账号、密码，用户就其账号及密码项下之一切活动负全部责任，包括用户数据的修改，虚拟道具的损失以及其他所有的损失由用户自行承担。用户须重视{{COMPANY_NAME_All}}直播网站账号密码保护。用户如发现他人未经许可使用其账号时立即通知{{COMPANY_NAME_All}}直播。<br />
    &emsp;&emsp;2.10<br />
    &emsp;&emsp;用户{{COMPANY_NAME_All}}直播网站账号在丢失或遗忘密码后，可遵照{{COMPANY_NAME_All}}直播的申诉途径及时申诉请求找回账号。用户应不断提供能增加账号安全性的个人密码保护资料。用户可以凭账号身份认证信息、初始注册资料及个人密码保护资料填写申诉单向{{COMPANY_NAME_All}}直播申请找回账号。{{COMPANY_NAME_All}}直播的密码找回机制仅负责识别申诉单上所填资料与系统记录资料的正确性，而无法识别申诉人是否系真正账号使用人。对用户因被他人冒名申诉而致的任何损失，{{COMPANY_NAME_All}}直播不承担任何责任，用户知晓{{COMPANY_NAME_All}}直播网站账号及密码保管责任在于用户，{{COMPANY_NAME_All}}直播并不承诺{{COMPANY_NAME_All}}直播网站账号丢失或遗忘密码后用户一定能通过申诉找回账号。<br />
    &emsp;&emsp;2.11<br />
    &emsp;&emsp;如果{{COMPANY_NAME_All}}直播网站账号的使用者存在使用归属争议，{{COMPANY_NAME_All}}直播将按照评判规则，根据用户提交的信息进行判定。{{COMPANY_NAME_All}}直播网站账号存在争议的情况下，{{COMPANY_NAME_All}}直播有权暂时中止争议账号的使用，由此带来的损失由用户自行承担，上述损失包括但不限于：用户与第三方发生的纠纷、通过第三方代充值、第三方充值赠与、非登录充值带来的充值损失，通信中断，用户资料、邮件和游戏道具及相关数据等的清空，相关服务中止等。<br />
    &emsp;&emsp;2.12<br />
    &emsp;&emsp;请用户注意勿在使用{{COMPANY_NAME_All}}直播网站服务中透露自己的各类财产账户、银行卡、信用卡、第三方支付账户及对应密码等重要资料，否则由此带来的任何损失由用户自行承担。<br />
    &emsp;&emsp;2.13<br />
    &emsp;&emsp;用户在使用{{COMPANY_NAME_All}}直播网站服务时不可将自认为隐私的信息发表、上传至{{COMPANY_NAME_All}}直播网站，也不可将该等信息通过{{COMPANY_NAME_All}}直播网站的服务传播给其他人。若用户的行为引起的隐私泄漏，由用户承担责任。<br />
    &emsp;&emsp;2.14 <br />
    &emsp;&emsp;就下列相关事宜的发生，{{COMPANY_NAME_All}}直播网站不承担任何法律责任：<br />
    &emsp;&emsp;（1）{{COMPANY_NAME_All}}直播根据法律规定或相关政府机关、安全机关、司法机关的要求提供用户的个人信息；<br />
    &emsp;&emsp;（2）由于用户将用户密码告知他人或与他人共享注册账户，由此导致的任何财产损失、虚拟财产损失、个人信息的泄漏，或其他非因{{COMPANY_NAME_All}}直播原因导致的上述损失或泄漏；<br />
    &emsp;&emsp;（3）任何由于黑客攻击、电脑病毒侵入造成的任何财产损失、虚拟财产损失、信息泄漏；<br />
    &emsp;&emsp;（4）因不可抗力导致的任何财产损失、虚拟财产损失、信息泄漏。 3、用户行为规则<br />
    &emsp;&emsp;3.1<br />
    &emsp;&emsp;用户保证使用{{COMPANY_NAME_All}}直播网站发布、传播的信息的真实性、准确性，同时保证不会利用{{COMPANY_NAME_All}}直播的服务制作、复制、发布、传播、存储含有下列内容的信息：<br />
    &emsp;&emsp;（1）反对宪法所确定的基本原则的；<br />
    &emsp;&emsp;（2）危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；<br />
    &emsp;&emsp;（3）损害国家荣誉和利益的； （4）煽动民族仇恨、民族歧视，破坏民族团结的；<br />
    &emsp;&emsp;（5）破坏国家宗教政策，宣扬邪教和封建迷信的；<br />
    &emsp;&emsp;（6）散布谣言，扰乱社会秩序，破坏社会稳定的；<br />
    &emsp;&emsp;（7）散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；<br />
    &emsp;&emsp;（8）侮辱或者诽谤他人，侵害他人知识产权或其他合法权益的；<br />
    &emsp;&emsp;（9）煽动非法集会、结社、游行、示威、聚众扰乱社会秩序的；<br />
    &emsp;&emsp;（10）含有虚假、有害、胁迫、侵害他人隐私、中伤、低俗、或其它道德上令人反感的内容的；<br />
    &emsp;&emsp;（11）含有法律、行政法规禁止的其他内容的。 <br />
    &emsp;&emsp;3.2<br />
    &emsp;&emsp;用户不得在{{COMPANY_NAME_All}}直播网站上违规进行广告发布、商品销售等商业行为，包括但不限于以下行为：<br />
    &emsp;&emsp;（1）发布宣传色情网站、成人用品网站等色情、低俗内容；<br />
    &emsp;&emsp;（2）发布宣传假冒伪劣产品或未经授权销售的产品内容；<br />
    &emsp;&emsp;（3）发布宣传国家明令禁止或未取得国家许可销售的产品内容；<br />
    &emsp;&emsp;（4）发布宣传游戏代挂、代练、虚假人气、贩卖虚拟货币、平台道具等内容；<br />
    &emsp;&emsp;（5）发布宣传游戏外挂、辅助等内容； <br />
    &emsp;&emsp;（6）发布组织、宣传网络兼职内容；<br />
    &emsp;&emsp;（7）宣传其他平台、网络游戏链接； <br />
    &emsp;&emsp;（8）恶意刷屏、扰乱平台秩序；<br />
    &emsp;&emsp;（9）其他违反法律法规或{{COMPANY_NAME_All}}直播平台规则的内容。<br /> 
    &emsp;&emsp;3.3<br />
    &emsp;&emsp;用户不得进行任何危害计算机网络安全的行为，包括但不限于：使用未经许可的数据或进入未经许可的服务器/账户；未经允许进入公众计算机网络或者他人计算机系统并删除、修改、增加存储信息；未经许可，企图探查、扫描、测试本“软件”系统或网络的弱点或其它实施破坏网络安全的行为；企图干涉、破坏本“软件”系统或网站的正常运行，故意传播恶意程序或病毒以及其他破坏干扰正常网络信息服务的行为；伪造TCP/IP数据包名称或部分名称。<br />
    &emsp;&emsp;3.4<br />
    &emsp;&emsp;用户不得进行任何破坏{{COMPANY_NAME_All}}直播服务公平性或者其他影响{{COMPANY_NAME_All}}直播网站正常秩序的行为，包括但不限于主动或被动刷分、合伙作弊、使用外挂或者其他的作弊软件、利用BUG（又叫“漏洞”或者“缺陷”）、恶意多开账号等违反公平和诚实信用原则的行为来获得不正当的非法利益，或者利用互联网或其他方式将外挂、作弊软件、BUG公之于众。不正当参与平台活动的用户，系统将自动甄别并剔除，{{COMPANY_NAME_All}}直播有权根据自身平台的大数据分析能力、技术能力进行判断，并判断相关行为是否违规，并对利用不正当手段获利的结果不予认可。<br />
    &emsp;&emsp;3.5<br />
    &emsp;&emsp;未经{{COMPANY_NAME_All}}直播书面许可，用户不得自行或授权、允许、协助任何第三人对{{COMPANY_NAME_All}}直播网站及其系统进行地址扫描、网络端口扫描、操作系统探测等扫描及/或探测，不得对{{COMPANY_NAME_All}}直播网站上的任何内容进行非法获取从而用于（包括但不限于）：违法销售个人信息、宣传、增加点击量、浏览量、提供付费服务等。
    “非法获取”是指采用非正常浏览的手段、方式非法获取、擅自使用{{COMPANY_NAME_All}}直播网站内任何内容的行为，具体行为包括但不限于：利用机器人、“蜘蛛”(spider)程序、爬虫程序、拟人程序等非自然用户行为或利用BUG（又叫“漏洞”或者“缺陷”）避开、破坏技术措施，对{{COMPANY_NAME_All}}直播网站内的任何内容进行监视、读取、复制、转存、展示、传播、镜像、上载、下载。<br />
    &emsp;&emsp;3.6 用户不得有其他损害{{COMPANY_NAME_All}}直播利益的行为。<br /> 
    &emsp;&emsp;3.7<br />
    &emsp;&emsp;若用户违反本协议的相关规定，{{COMPANY_NAME_All}}直播有权根据情节轻重，采取包括但不限于以下处罚措施：暂时限制账号的使用、永久冻结账号某项功能、永久封停账号、回收账号、剔除违法所得、扣罚保证金或佣金、公示处罚、终止所有服务等；因用户违反本协议，导致或产生第三方主张的任何索赔或损失，用户应当独立承担责任，给{{COMPANY_NAME_All}}直播造成损失的，{{COMPANY_NAME_All}}直播有权要求用户赔偿。<br />
    &emsp;&emsp;3.8<br />
    &emsp;&emsp;{{COMPANY_NAME_All}}直播按照本协议及相关平台规则或相关法律法规，限制、冻结或终止用户对{{COMPANY_NAME_All}}直播服务的使用，而由此给用户带来的损失（包括但不限于因用户违规行为导致与第三方发生的纠纷、通过第三方代充值、第三方充值赠与、非登录充值带来的充值损失，通信中断，用户资料、邮件和游戏道具及相关数据等的清空、相关服务中止等），由用户自行承担。<br />
    4、告知声明<br />
    &emsp;&emsp;4.1<br />
    &emsp;&emsp;用户须明白，在使用{{COMPANY_NAME_All}}直播服务的过程中可能存在来自他人包括威胁性的、诽谤性的、令人反感的或非法的内容或行为，或侵犯他人权利（包括知识产权）的匿名或冒名的信息风险，用户须承担以上风险，{{COMPANY_NAME_All}}直播对服务不作担保，不论是明确的或隐含的，包括所有有关信息真实性、适商性、适于某一特定用途、所有权和非侵权性的默示担保和条件，对因此导致任何因用户不正当或非法使用服务产生的直接、间接、偶然、特殊及后续的损害，不承担任何责任。<br />
    &emsp;&emsp;4.2<br />
    &emsp;&emsp;用户使用{{COMPANY_NAME_All}}直播服务必须遵守国家有关法律和政策等，维护国家利益，保护国家安全，并遵守本条款，对于用户违法或违反本协议的使用(包括但不限于言论发表、传送等)而引起的一切责任，由用户负全部责任。<br />
    &emsp;&emsp;4.3<br />
    &emsp;&emsp;{{COMPANY_NAME_All}}直播的服务同大多数因特网产品一样，易受到各种安全问题的困扰，包括但不限于：1）透露详细个人资料，被不法分子利用，造成现实生活中的骚扰；2）哄骗、破译密码；3）下载安装的其它软件中含有“特洛伊木马”等病毒，威胁到个人计算计上信息和数据的安全，继而影响对本软件的使用。对于发生上述情况的，用户应当自行承担责任。<br />
    &emsp;&emsp;4.4<br />
    &emsp;&emsp;用户理解，互联网技术的不稳定性，可能导致政府政策管制、病毒入侵、黑客攻击、服务器系统崩溃或者其他现今技术无法解决的风险发生，上述风险可能导致{{COMPANY_NAME_All}}直播服务中断或账号道具损失，对此等非人为因素引起的损失{{COMPANY_NAME_All}}直播不承担责任。<br />
    &emsp;&emsp;4.5<br />
    &emsp;&emsp;{{COMPANY_NAME_All}}直播不对发布在本网站上的广告的产品效果、宣传、信息准确性负责，用户在接触这些广告时自行判断。<br />
    5、知识产权 <br />
    &emsp;&emsp;5.1<br />
    &emsp;&emsp;{{COMPANY_NAME_All}}直播的文字、图片、视频、音频、软件等元素，{{COMPANY_NAME_All}}直播服务标志、标识以及专利权，{{COMPANY_NAME_All}}直播对此享有上述所有内容的知识产权。<br />
    &emsp;&emsp;5.2<br />
    &emsp;&emsp;用户使用{{COMPANY_NAME_All}}直播网站服务只能在本协议以及相应的授权许可协议授权的范围使用{{COMPANY_NAME_All}}直播知识产权，未经授权超范围使用的构成对{{COMPANY_NAME_All}}直播的侵权。<br />
    &emsp;&emsp;5.3<br />
    &emsp;&emsp;用户在使用{{COMPANY_NAME_All}}直播网站服务时发布上传的文字、图片、音视频、软件以及表演等用户原创的信息，此部分信息的相关权利归属用户，但用户的发表、上传行为是对{{COMPANY_NAME_All}}直播服务平台的授权，用户确认将其发表、上传的信息非独占性、永久性地授权给{{COMPANY_NAME_All}}直播，该授权可转授权。{{COMPANY_NAME_All}}直播可将前述信息在{{COMPANY_NAME_All}}直播旗下的服务平台上使用，可再次编辑后使用，也可以由{{COMPANY_NAME_All}}直播授权给合作方使用。<br />
    &emsp;&emsp;5.4<br />
    &emsp;&emsp;若{{COMPANY_NAME_All}}直播网站内的信息以及其他用户上传、存储、传播的信息有侵犯用户或第三人的知识产权的，{{COMPANY_NAME_All}}直播提供专属投诉通道。<br />
    6、收费服务 <br />
    &emsp;&emsp;6.1<br />
    &emsp;&emsp;{{COMPANY_NAME_All}}直播网站部分功能或应用涉及付费，用户输入账号密码的行为视为对收费功能的接受和订阅，用户一旦接受订阅收费服务，需要按{{COMPANY_NAME_All}}直播公示的收费标准支付费用。<br />
    &emsp;&emsp;6.2<br />
    &emsp;&emsp;用户在进行消费时，请事先了解消费价格及事项；如用户是未成年人，在未取得监护人明示同意的前提下，请勿使用充值消费服务。<br />
    &emsp;&emsp;6.3 用户付费后获得的权益不得转让给第三人。 <br />
    7、通知 <br />
    &emsp;&emsp;7.1<br />
    &emsp;&emsp;用户同意{{COMPANY_NAME_All}}直播以以下合理的方式向用户送达各类通知： <br />
    &emsp;&emsp;（1）公示的文案；<br />
    &emsp;&emsp;（2）系统消息、弹出消息、客户端推送的消息；<br />
    &emsp;&emsp;（3）根据用户预留于{{COMPANY_NAME_All}}直播网站的联系方式发出的电子邮件、手机短信、函件等。<br />
    &emsp;&emsp;7.2<br />
    &emsp;&emsp;对于在{{COMPANY_NAME_All}}直播网站上因交易活动引起的任何纠纷，用户同意司法机关可以通过手机短信、电子邮件等现代通讯方式或邮寄方式向用户送达法律文书。用户指定接收法律文书的手机号码、电子邮箱等联系方式为用户在{{COMPANY_NAME_All}}直播网站注册、更新时提供的手机号码、电子邮箱联系方式，司法机关向上述联系方式发出法律文书即视为送达。用户指定的邮寄地址为用户的法定联系地址或用户提供的有效联系地址。<br />
    &emsp;&emsp;7.3<br />
    &emsp;&emsp;用户同意司法机关可采取以上一种或多种送达方式向用户达法律文书，司法机关采取多种方式向用户送达法律文书，送达时间以上述送达方式中最先送达的为准。<br />
    &emsp;&emsp;7.4<br />
    &emsp;&emsp;用户应当保证所提供的联系方式是准确、有效的，并进行实时更新。如果因提供的联系方式不确切，或不及时告知变更后的联系方式，使法律文书无法送达或未及时送达，由用户自行承担由此可能产生的法律后果。<br />
    8、其他条款 <br />
    &emsp;&emsp;8.1<br />
    &emsp;&emsp;本协议条款的签订、解释以及争议的解决均适用中华人民共和国法律。<br /> 
    &emsp;&emsp;8.2<br />
    &emsp;&emsp;若用户与{{COMPANY_NAME_All}}直播发生争议的，双方同意将争议提交被告方住所地有管辖权的人民法院诉讼解决。<br />
    &emsp;&emsp;8.3<br />
    &emsp;&emsp;本协议由{{COMPANY_NAME_All}}直播公布在网站上，对{{COMPANY_NAME_All}}直播具有法律约束力；用户一经点击接受或者直接登录、查看、发布、使用的行为视为对本协议的接受，对用户具有法律约束力。
  </div>
</template>

<script>
export default {
  data(){
    return{
      COMPANY_NAME_All:process.env.VUE_APP_COMPANY_NAME_All,
      COMPANY_NAME_ABB:process.env.VUE_APP_COMPANY_NAME_ABB,
    }
  }
};
</script>

<style lang="less" scoped>
.box {
  padding: 0 200px;
  font-size:12px
}
</style>